import React from "react";
import logo from "./logo.png";
import preview from "./preview.mp4";
import "./App.css";

function App() {
  return (
    <div className="App">
      <video autoPlay loop muted playsInline id="background-video">
        <source src={preview} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>Eccentric, Unique, Bespoque</p>
      </header>

      <div className="footer">
      <a href="/pitch-deck.pdf" download="PitchDeck.pdf">Pitch Deck</a>
      <a href="mailto:info@bespoque.eu">Contact Us</a>
      </div>
    </div>
  );
}

export default App;
